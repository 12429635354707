<template>
  <div>

    <template v-for="(question, questionIndex) in getNewQuestions">
      <div class="card" :class="'card_' + questionIndex">

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h5 class="card-title">Soru #{{ questionIndex + 1 }}</h5>
            <a href="javascript:void(0)" @click="removeQuestionRow(questionIndex)"
              :uk-tooltip="$t('general.remove_from_list')" class="btn btn-icon btn-hover btn-lg btn-circle" title=""
              aria-expanded="false">
              <i class="uil-times-circle text-danger"></i>
            </a>
          </div>


          <div class="form-group row mb-3">
            <label class="col-md-3 col-form-label" :for="'type_' + questionIndex">{{ $t('question.attributes.q_type') }}
              <span class="required">*</span></label>
            <div class="col-md-9">
              <select @change="changedQuestionType(questionIndex)" class="selectpicker1" v-model="question.type"
                :id="'type_' + questionIndex">
                <option v-for="questionType in questionTypes" :value="questionType.id">
                  <i :class="questionType.icon"></i> {{ questionType.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mb-3" v-if="question.type === 3 || question.type === 5">
            <label class="col-md-3 col-form-label">{{ $t('question.attributes.image') }} <span
                class="required">*</span></label>
            <div class="col-md-9">
              <div class="uploadOuter">
                <label :for="'questionImageUpload_' + questionIndex" class="btn btn-info"><i class="uil-upload"></i> {{
                  $t('general.upload') }}</label>
                <strong>{{ $t('general.or') }}</strong>
                <span class="dragBox">
                  <i class="icon-feather-file-plus"></i> {{ $t('general.doc_file_drag_drop') }}
                  <input accept="image/*" type="file" @change="dragNdrop(questionIndex, $event)"
                    :id="'questionImageUpload_' + questionIndex">
                </span>
              </div>
              <div :id="'preview_image_' + questionIndex" class="filePreview" :class="'preview_image_' + questionIndex"
                v-html="question.preview"></div>
            </div>
          </div>

          <div class="form-group row mb-3" v-if="question.type === 7">
            <label class="col-md-3 col-form-label">{{ $t('general.audio_file') }} <span
                class="required">*</span></label>
            <div class="col-md-9">
              <div class="uploadOuter">
                <label :for="'questionAudioUpload_' + questionIndex" class="btn btn-info"><i class="uil-upload"></i> {{
                  $t('general.upload') }}</label>
                <strong>{{ $t('general.or') }}</strong>
                <span class="dragBox">
                  <i class="icon-feather-file-plus"></i> {{ $t('general.audio_file_drag_drop') }}
                  <input accept="audio/*" type="file" @change="dragNdropAudio(questionIndex, $event)"
                    :id="'questionAudioUpload_' + questionIndex">
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="question.audioPreview" :id="'preview_audio_' + questionIndex" v-html="question.audioPreview">
              </div>
            </div>
          </div>

          <div class="form-group row mb-3" v-if="question.type === 8">
            <label class="col-md-3 col-form-label">{{ $t('general.video_file') }} <span
                class="required">*</span></label>
            <div class="col-md-9">
              <div class="uploadOuter">
                <label :for="'questionVideoUpload_' + questionIndex" class="btn btn-info"><i class="uil-upload"></i>{{
                  $t('general.upload') }}</label>
                <strong>{{ $t('general.or') }}</strong>
                <span class="dragBox">
                  <i class="icon-feather-file-plus"></i> {{ $t('general.video_file_drag_drop') }}
                  <input accept="video/*" type="file" @change="dragNdropVideo(questionIndex, $event)"
                    :id="'questionVideoUpload_' + questionIndex">
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div v-if="question.videoPreview" :id="'preview_video_' + questionIndex" v-html="question.videoPreview">
              </div>
            </div>
          </div>

          <div class="form-group row mb-3">
            <label class="col-md-3 col-form-label" :for="'question_text_' + questionIndex">{{
              $t('question.attributes.text') }} <span class="required">*</span></label>
            <div class="col-md-9">
              <!-- <textarea :id="'question_text_' + questionIndex" v-model="question.text" class="form-control"></textarea> -->
              <div id="app">
                <vue-editor v-model="question.text" @image-added="onImageUpload"></vue-editor>
              </div>
            </div>
          </div>

          <!-- Açık Uçlu Soru -->
          <!--          <div class="form-group row mb-3" v-if="question.type === 6">-->
          <!--            <label class="col-md-3 col-form-label" :for="'options_text_' + questionIndex">{{ $t('question.attributes.answer') }} <span class="required">*</span></label>-->
          <!--            <div class="col-md-9">-->
          <!--              <textarea v-model="question.options[0].name" :id="'options_text_' + questionIndex" class="form-control"></textarea>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="form-group row mb-3" v-if="question.type !== 6">
            <label class="col-md-3 col-form-label">{{ $t('question.attributes.answer_options') }} <span
                class="required">*</span></label>

            <div class="col-md-9">
              <table :id="'myTable_' + questionIndex" class="table order-list">
                <thead>
                  <tr>
                    <td>{{ $t('question.attributes.answer_text') }}</td>
                    <td class="text-center">{{ $t('general.status') }}</td>
                    <td class="text-right">{{ $t('general.action') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(option, optionIndex) in question.options">
                    <td class="col-sm-4">
                      <div class="form-group row mb-3" v-if="question.type === 4 || question.type === 5">
                        <div class="col-md-12">
                          <div class="uploadOuter">
                            <label :for="'uploadFileReply_' + questionIndex + '_' + optionIndex" class="btn btn-info"><i
                                class="uil-upload"></i> {{ $t('general.upload') }}</label>
                            <br>
                            <strong>{{ $t('general.or') }}</strong>
                            <span class="dragBox">
                              <i class="icon-feather-file-plus"></i> {{ $t('general.doc_file_drag_drop') }}
                              <input accept="image/*" type="file" :data-index="optionIndex" ref="replies"
                                @change="dragNdropReply(questionIndex, optionIndex, $event)"
                                :id="'uploadFileReply_' + questionIndex + '_' + optionIndex">
                            </span>
                          </div>
                          <div v-if="typeof option.preview !== 'undefined'">
                            <div v-html="option.preview"></div>
                          </div>
                          <div :id="'preview_' + questionIndex + '_' + optionIndex"
                            :class="'preview_' + questionIndex + '_' + optionIndex"></div>

                        </div>
                      </div>
                      <input v-if="checkQuestionType(question.type)" type="text" required name="reply_text"
                        v-model="option.name" class="form-control">
                    </td>

                    <td class="col-sm-4">
                      <div
                        class="d-flex align-content-center justify-content-center table-vertical-center align-items-center">
                        <toggle-button :width="80" v-model="option.is_correct" color="#ae71ff" :sync="true"
                          :labels="{ checked: $t('question.attributes.true'), unchecked: $t('question.attributes.false') }" />
                      </div>
                    </td>

                    <td class="col-sm-4">
                      <div class="actions mr-2 float-right">
                        <a href="javascript:void(0)" @click="removeOptionRow(questionIndex, optionIndex)"
                          :uk-tooltip="$t('general.remove_from_list')" class="btn btn-icon btn-hover btn-lg btn-circle"
                          title="" aria-expanded="false">
                          <i class="uil-times-circle text-danger"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="5" style="text-align: left;">
                      <input @click="addOptionRow(questionIndex)" type="button" class="btn btn-lg btn-outline-primary"
                        :value="$t('general.add')" />
                    </td>
                  </tr>
                  <tr>
                  </tr>
                </tfoot>
              </table>
            </div>

          </div>

        </div>
      </div>
    </template>

    <div class="text-right">
      <button type="button" class="btn mb-2" @click="addQuestionRow"><i class="icon-feather-plus-circle"></i> Soru
        Ekle</button>
    </div>

  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import store from "@/core/services";
import {
  GET_ITEMS as QUESTION_REST_ITEMS,
  LOADING as QUESTION_REST_LOADING,
  ERROR as QUESTION_REST_ERROR,
} from "@/core/services/store/REST.module";

export default {
  name: "Questions",
  components: {
    VueEditor
  },

  // beforeCreate () {
  //   function registerStoreModule (moduleName, storeModule) {
  //     if (!(store && store.state && store.state[moduleName])) {
  //       store.registerModule(moduleName, storeModule)
  //     }
  //   }
  //   registerStoreModule(QUESTION_REST_MODULE_NAME, module)
  // },
  props: {
    importedExam: [],
    modelType: {
      required: true,
      default: '', // Survey or Exam
    },
    action: {
      required: true,
      default: '',
    },
    questions: {
      required: true,
      default: [],
    },
  },
  data() {
    return {
      questionTypes: [],
      newQuestions: [],
      questionForm: {
        id: 0,
        type: 1,
        text: '',
        media: '',
        options: [{ id: 0, name: '', is_correct: false }],
        preview: null,
        audioPreview: null,
        videoPreview: null,
        previewReply: [],
      },
    }
  },
  computed: {
    errors: {
      get() {
        return store.getters[QUESTION_REST_ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[QUESTION_REST_LOADING]
    },
    getNewQuestions() {

      if (this.action === 'edit') {
        if (this.newQuestions.length === 0) {
          this.newQuestions = this.questions;
        }
      }
      if (this.action === 'create') {
        if (this.newQuestions.length === 0) {
          let myForm = Object.assign({}, this.questionForm);
          this.newQuestions.push(myForm);
        }
      }
      return this.newQuestions;
    },
  },
  methods: {
    onImageUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        this.insertImageToEditor(base64String);
      };

      reader.readAsDataURL(file);
    },
    insertImageToEditor(base64String) {
      // img tagini base64 ile oluşturma
      const imgTag = `<img src="${base64String}" alt="Image"/>`;

      // Editöre HTML olarak ekleme
      this.$refs.editor.quillEditor.clipboard.dangerouslyPasteHTML(this.$refs.editor.quillEditor.getSelection().index, imgTag);
    },
    handleNewQuestions() {
      return this.newQuestions;
    },
    addQuestionRow() {
      let myForm = Object.assign({}, this.questionForm);
      myForm.options = [{ id: 0, name: '', is_correct: false }];
      this.newQuestions.push(myForm);
    },
    removeQuestionRow(questionIndex) {
      this.newQuestions.splice(questionIndex, 1);
    },
    addOptionRow(questionIndex) {
      this.newQuestions[questionIndex].options.push({ id: 0, name: "", is_correct: false });
    },
    removeOptionRow(questionIndex, optionIndex) {
      this.newQuestions[questionIndex].options.splice(optionIndex, 1);
    },
    changedQuestionType(questionIndex) {

      this.newQuestions[questionIndex].media = '';
      this.newQuestions[questionIndex].preview = '';
      this.newQuestions[questionIndex].audioPreview = '';
      this.newQuestions[questionIndex].videoPreview = '';
      this.newQuestions[questionIndex].previewReply = [];
      this.newQuestions[questionIndex].options = [{ id: 0, name: '', is_correct: false }];

      $("div.card_" + questionIndex).find("div[class^='preview_']").html("");
      $("div.card_" + questionIndex).find('input[type="file"]').val("");

    },

    checkQuestionType(questionType) {
      let arr = [1, 2, 3, 7, 8];

      return arr.includes(questionType);
    },

    dragNdrop(questionIndex, event) {
      let file = event.target.files[0];
      //this.form.media = file;
      this.newQuestions[questionIndex].media = file;

      this.newQuestions[questionIndex].preview = '<div class="file-info">' +
        '<div>Format: <span>' + file.type + '</span></div>' +
        '<div>Doküman Adı: <span>' + file.name + '</span></div>' +
        '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
        '</div><br>' +
        '<div><img src="' + URL.createObjectURL(file) + '" alt=""></div>';
    },

    dragNdropAudio(questionIndex, event) {
      let file = event.target.files[0];
      this.newQuestions[questionIndex].media = file;

      this.newQuestions[questionIndex].audioPreview = '<div class="file-info">' +
        '<div>Format: <span>' + file.type + ' <i class="uil-microphone" style="font-size: 14px"></i></span></div>' +
        '<div>Ses Dosyası Adı: <span>' + file.name + '</span></div>' +
        '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
        '</div>';
    },

    dragNdropVideo(questionIndex, event) {
      let file = event.target.files[0];
      this.newQuestions[questionIndex].media = file;

      this.newQuestions[questionIndex].videoPreview = '<div class="file-info">' +
        '<div>Format: <span>' + file.type + ' <i class="uil-video" style="font-size: 14px"></i></span></div>' +
        '<div>Video Dosyası Adı: <span>' + file.name + '</span></div>' +
        '<div>Boyut: <span>' + this.bytesToSize(file.size) + '</span></div>' +
        '</div>';
    },

    dragNdropReply(questionIndex, optionIndex, event) {
      let self = this;
      let file = event.target.files[0];
      let blob = URL.createObjectURL(file);
      let html = '<div class="file-info">' +
        '<div>Format: <span>' + file.type + '</span></div>' +
        '<div>Doküman Adı: <span>' + file.name + '</span></div>' +
        '<div>Boyut: <span>' + self.bytesToSize(file.size) + '</span></div>' +
        '</div><br>' +
        '<div><img src="' + blob + '" alt=""></div>';

      $('#preview_' + questionIndex + '_' + optionIndex).html(html);
      self.newQuestions[questionIndex].options[optionIndex].preview = '';
      self.newQuestions[questionIndex].options[optionIndex].media = file;
    },

  },
  mounted() {
    store.dispatch(QUESTION_REST_ITEMS, {
      url: 'api/question_types',
    }).then(response => {
      if (response.status) {
        this.questionTypes = response.data;

        // Açık uçlu soru tipi listeden kaldırılıyor
        this.questionTypes = this.questionTypes.filter((item, index) => {
          return item.id === 1;
        });
      }

    })
  },
  watch: {
    importedExam: function (val) {
      this.action = 'create';
      this.newQuestions = [];
      this.importedExam.forEach((data, examIndex) => {
        if (examIndex === 0)
          return true
        if (examIndex === 1) {
        }
        if (examIndex > 1) {
          this.addQuestionRow()
        }
        data.forEach((option, optionIndex) => {
          if (optionIndex === 0) {
            this.getNewQuestions[examIndex - 1]['text'] = data[0]
          }
          else {
            if (optionIndex === data.length - 1) {
              if (option.toLowerCase() === 'a') {
                this.getNewQuestions[examIndex - 1]['options'][0]['is_correct'] = true
                return true
              }
              else if (option.toLowerCase() === 'b') {
                this.getNewQuestions[examIndex - 1]['options'][1]['is_correct'] = true
                return true
              }
              else if (option.toLowerCase() === 'b') {
                this.getNewQuestions[examIndex - 1]['options'][2]['is_correct'] = true
                return true
              }
              else if (option.toLowerCase() === 'c') {
                this.getNewQuestions[examIndex - 1]['options'][3]['is_correct'] = true
                return true
              }
              else if (option.toLowerCase() === 'd') {
                this.getNewQuestions[examIndex - 1]['options'][4]['is_correct'] = true
                return true
              }
              else if (option.toLowerCase() === 'e') {
                this.getNewQuestions[examIndex - 1]['options'][5]['is_correct'] = true
                return true
              }
            } else if (optionIndex !== 1 && this.getNewQuestions[examIndex - 1]['options'].length < 5) {
              this.addOptionRow(examIndex - 1)
            }
            this.getNewQuestions[examIndex - 1]['options'][optionIndex - 1]['name'] = data[optionIndex]
          }
        });
      });
    },

  },
}

</script>

<style scoped></style>
